import React, { useState } from 'react';
import axios from 'axios';
import { Box, Button, TextField, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoHorizon from '../images/hyrogen_logo_horizental.png';

const Login = () => {
  const [id, setId] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({ id: '', password: '' });
  const [loginError, setLoginError] = useState(''); // 로그인 오류 메시지 상태 추가
  const navigate = useNavigate();

  const validate = () => {
    let valid = true;
    let newErrors = { id: '', password: '' };

    const idRegex = /^[a-z0-9]{5,}$/;
    if (!idRegex.test(id)) {
      newErrors.id =
        '아이디는 알파벳 소문자와 숫자로 이루어진 5자 이상이어야 합니다.';
      valid = false;
    }

    const passwordRegex = /^[a-z0-9]{5,}$/;
    if (!passwordRegex.test(password)) {
      newErrors.password =
        '비밀번호는 알파벳 소문자와 숫자로 이루어진 5자 이상이어야 합니다.';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!validate()) return;

    try {
      const response = await axios.post('https://api.h2rogen.com/signin', {
        id,
        password,
      });

      console.log(response.data); // 응답 데이터 콘솔에 찍어보기

      if (response.data.status === 'success') {
        const token = response.data.token;
        console.log('로그인 성공, 받은 토큰:', token);
        // JWT 토큰을 로컬 스토리지에 저장
        localStorage.setItem('token', token);
        console.log('저장된 토큰:', localStorage.getItem('token'));

        // 홈 페이지로 이동
        navigate('/dashboard');
        console.log('대시보드로 이동');
      } else {
        // status가 success가 아닐 경우
        console.log('로그인 실패, 상태:', response.data.status);
        setLoginError('아이디 또는 비밀번호가 잘못되었습니다.');
      }
    } catch (error) {
      // 오류가 발생했을 경우
      console.error('로그인 요청 중 오류 발생:', error);
      setLoginError('아이디 또는 비밀번호가 잘못되었습니다.');
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 8,
        }}
      >
        {/* 왼쪽 로고 */}
        <img
          src={LogoHorizon}
          alt="Hyrogen Logo"
          style={{
            width: '105px',
            marginBottom: '56px', // 로고와 폼 사이의 간격 조정
          }}
        />
        <Typography component="h1" variant="h5">
          로그인
        </Typography>
        <Box
          component="form"
          onSubmit={handleLogin}
          noValidate
          sx={{ mt: 1, width: '100%' }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="id"
            name="id"
            placeholder="아이디를 입력해주세요"
            autoFocus
            value={id}
            onChange={(e) => setId(e.target.value)}
            error={!!errors.id}
            helperText={errors.id}
            sx={{ height: '76px' }}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            type="password"
            id="password"
            placeholder="비밀번호를 입력해주세요"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
            sx={{ height: '76px' }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2, height: '48px' }}
          >
            로그인
          </Button>
          {/* 로그인 오류 메시지 추가 */}
          {loginError && (
            <Typography
              variant="body1"
              color="error"
              sx={{
                textAlign: 'center',
                mt: 2,
                fontSize: '14px', // 원하는 글자 크기 설정
              }}
            >
              {loginError}
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
