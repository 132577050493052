import React, { useEffect, useState } from 'react';
import { useDataProvider } from '../context/DataProvider';
import ShowChartIcon from '@mui/icons-material/ShowChart'; // 그래프 아이콘
import GridViewIcon from '@mui/icons-material/GridView';

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from 'recharts';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@mui/material';

const formatDate = (dateString) => {
  if (
    !dateString ||
    typeof dateString !== 'string' ||
    dateString.length === 0
  ) {
    return 'Invalid date';
  }
  if (dateString.length === 6) {
    const year = dateString.substring(0, 4);
    const month = parseInt(dateString.substring(4, 6), 10);
    return `${year}년 ${month}월`;
  } else {
    const year = dateString.substring(0, 4);
    const month = parseInt(dateString.substring(4, 6), 10);
    const day = parseInt(dateString.substring(6, 8), 10);
    return `${year}년 ${month}월 ${day}일`;
  }
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ddd',
          fontSize: '12px',
          lineHeight: '1.5',
        }}
      >
        <p style={{ margin: 0, fontWeight: 'bold' }}>
          날짜: {formatDate(label)}
        </p>
        {payload.map((entry, index) => {
          let nameInKorean = '';
          switch (entry.dataKey) {
            case 'landHighPrice':
              nameInKorean = '육지최고가격';
              break;
            case 'landAvgPrice':
              nameInKorean = '육지평균가격';
              break;
            case 'landLowPrice':
              nameInKorean = '육지최저가격';
              break;
            case 'landClosePrice':
              nameInKorean = '육지시장종가';
              break;
            case 'landMaxPrice':
              nameInKorean = '육지최대평균';
              break;
            case 'landMinPrice':
              nameInKorean = '육지최소평균';
              break;
            case 'jejuHighPrice':
              nameInKorean = '제주도최고가격';
              break;
            case 'jejuAvgPrice':
              nameInKorean = '제주도평균가격';
              break;
            case 'jejuMaxPrice':
              nameInKorean = '제주도최대평균';
              break;
            case 'jejuMinPrice':
              nameInKorean = '제주도최소평균';
              break;
            case 'jejuLowPrice':
              nameInKorean = '제주도최저가격';
              break;
            default:
              nameInKorean = entry.name; // 기본적으로는 원래 이름 사용
          }
          return (
            <p
              key={index}
              style={{
                color: entry.color,
                margin: 0,
              }}
            >
              {nameInKorean}: {entry.value} 원
            </p>
          );
        })}
      </div>
    );
  }
  return null;
};

const formatMonthWithSkipFirst = (value, index) => {
  if (index === 0) return '';
  const month = value.substring(4, 6);
  return `${parseInt(month, 10)}월`;
};

const RecCard = () => {
  const [recData, setRecData] = useState([]);
  const [displayMode, setDisplayMode] = useState('daily'); // 일별 또는 월별
  const [chartType, setChartType] = useState('graph'); // 차트 또는 그래프
  const dataProvider = useDataProvider();

  useEffect(() => {
    const fetchRecData = async (retryCount = 3) => {
      try {
        const data = await dataProvider.getList({
          endpoint: 'info/rec',
        });
        setRecData(data);
      } catch (err) {
        console.error('Data fetching error:', err.message);

        if (retryCount > 0) {
          console.log(`Retrying... Attempts left: ${retryCount - 1}`);
          // 타이머를 이용해 일정 시간 후 재시도
          setTimeout(() => {
            fetchRecData(retryCount - 1); // 재시도
          }, 1000); // 1초 후 재시도
        } else {
          console.error('Failed to fetch data after multiple attempts');
        }
      }
    };
    fetchRecData();
  }, [dataProvider]);

  const minYValue =
    Math.floor(
      Math.min(
        ...recData.map((d) => Math.min(d.landLowPrice, d.jejuLowPrice))
      ) / 10000
    ) * 10000;
  const maxYValue =
    Math.ceil(
      Math.max(
        ...recData.map((d) => Math.max(d.landHighPrice, d.jejuHighPrice))
      ) / 10000
    ) * 10000;

  const getMonthlyData = () => {
    const monthlyData = {};
    recData.forEach(
      ({ date, landHighPrice, landLowPrice, jejuHighPrice, jejuLowPrice }) => {
        const month = date.substring(0, 6);
        if (!monthlyData[month]) {
          monthlyData[month] = {
            month,
            landHighPrices: [],
            landLowPrices: [],
            jejuHighPrices: [],
            jejuLowPrices: [],
          };
        }
        monthlyData[month].landHighPrices.push(landHighPrice);
        monthlyData[month].landLowPrices.push(landLowPrice);
        monthlyData[month].jejuHighPrices.push(jejuHighPrice);
        monthlyData[month].jejuLowPrices.push(jejuLowPrice);
      }
    );

    return Object.values(monthlyData).map(
      ({
        month,
        landHighPrices,
        landLowPrices,
        jejuHighPrices,
        jejuLowPrices,
      }) => ({
        month,
        landAvgPrice: (
          landHighPrices.reduce((a, b) => a + b, 0) / landHighPrices.length
        ).toFixed(0), // 육지 평균가격 소숫점 0번째 자리까지
        landMaxPrice: Math.max(...landHighPrices),
        landMinPrice: Math.min(...landLowPrices),
        jejuAvgPrice: (
          jejuHighPrices.reduce((a, b) => a + b, 0) / jejuHighPrices.length
        ).toFixed(0), // 제주도 평균가격 소숫점 0번째 자리까지
        jejuMaxPrice: Math.max(...jejuHighPrices),
        jejuMinPrice: Math.min(...jejuLowPrices),
      })
    );
  };

  const monthlyData = getMonthlyData();

  const handleButtonClick = (mode) => {
    setDisplayMode(mode);
  };

  const handleChartTypeChange = (type) => {
    setChartType(type);
  };

  return (
    <div style={{ position: 'relative', marginBottom: '0px' }}>
      {/* 버튼 영역 */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between', // 양쪽 끝으로 배치
          alignItems: 'center',
          width: '100%', // 버튼 그룹들이 화면 전체에 퍼지도록 설정
          position: 'absolute',
          top: -10,
          zIndex: 10,
        }}
      >
        {/* 왼쪽 - 차트/그래프 버튼 그룹 */}
        <div style={{ display: 'flex', gap: '5px', marginLeft: 10 }}>
          {' '}
          {/* 차트/그래프 버튼을 붙임 */}
          <button
            style={{
              padding: '1px 3.5px',
              backgroundColor: chartType === 'graph' ? '#007BFF' : '#ddd',
              color: chartType === 'graph' ? '#fff' : '#000',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={() => handleChartTypeChange('graph')}
          >
            <ShowChartIcon /> {/* 그래프 아이콘 */}
          </button>
          <button
            style={{
              padding: '1px 3.5px',
              backgroundColor: chartType === 'grid' ? '#007BFF' : '#ddd',
              color: chartType === 'grid' ? '#fff' : '#000',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={() => handleChartTypeChange('grid')}
          >
            <GridViewIcon />
          </button>
        </div>

        {/* 오른쪽 - 일/월 버튼 그룹 */}
        <div style={{ display: 'flex', gap: '5px', marginRight: 10 }}>
          {' '}
          {/* 일/월 버튼을 붙임 */}
          <button
            style={{
              padding: '5px 10px',
              backgroundColor: displayMode === 'daily' ? '#007BFF' : '#ddd',
              color: displayMode === 'daily' ? '#fff' : '#000',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={() => handleButtonClick('daily')}
          >
            일
          </button>
          <button
            style={{
              padding: '5px 10px',
              backgroundColor: displayMode === 'monthly' ? '#007BFF' : '#ddd',
              color: displayMode === 'monthly' ? '#fff' : '#000',
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
            onClick={() => handleButtonClick('monthly')}
          >
            월
          </button>
        </div>
      </div>

      {/* 테이블이나 다른 컴포넌트 추가 */}
      <div style={{ marginTop: '0px' }}>
        {/* 여기에서 테이블이나 다른 내용을 표시 */}
      </div>

      {/* displayMode와 chartType에 따라 데이터를 렌더링 */}
      <div
        style={{
          border: '1px solid transparent',
          borderRadius: '0px',
          padding: '15px',
          backgroundColor: 'white',
        }}
      ></div>
      {displayMode === 'daily' && chartType === 'graph' ? (
        // 일별 그래프
        <div style={{ height: '100%' }}>
          {/* Daily 육지 & 제주도 데이터 */}
          <div style={{ marginBottom: '10px', width: '100%', height: '100%' }}>
            <div style={{ position: 'relative' }}>
              <span
                style={{
                  position: 'absolute',
                  top: -10,
                  left: 0,
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              >
                육지
              </span>
            </div>
            <ResponsiveContainer width="100%" height={150}>
              <LineChart
                data={recData}
                margin={{ top: 17, left: -15, right: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={formatMonthWithSkipFirst}
                  interval={7}
                  tick={{ fontFamily: 'Roboto', fontSize: 12, fill: '#555' }}
                />
                <YAxis
                  domain={['auto', (dataMax) => Math.ceil(dataMax * 1.01)]}
                  tickFormatter={(value) => `${value / 10000}만`}
                  tick={{ fontFamily: 'Roboto', fontSize: 12 }}
                />
                <RechartsTooltip content={<CustomTooltip />} />
                <Line
                  type="monotone"
                  dataKey="landHighPrice"
                  stroke="#8884d8"
                  dot={false}
                  isAnimationActive={false}
                />
                <Line
                  type="monotone"
                  dataKey="landAvgPrice"
                  stroke="#82ca9d"
                  dot={false}
                  isAnimationActive={false}
                />
                <Line
                  type="monotone"
                  dataKey="landLowPrice"
                  stroke="#ffc658"
                  dot={false}
                  isAnimationActive={false}
                />
                <Line
                  type="monotone"
                  dataKey="landClosePrice"
                  stroke="#FF6347"
                  dot={false}
                  isAnimationActive={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div>
            <div style={{ position: 'relative' }}>
              <span
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              >
                제주도
              </span>
            </div>
            <ResponsiveContainer width="100%" height={160}>
              <LineChart
                data={recData}
                margin={{ top: 30, left: -15, right: 10 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="date"
                  tickFormatter={formatMonthWithSkipFirst}
                  interval={7}
                  tick={{ fontFamily: 'Roboto', fontSize: 12, fill: '#555' }}
                />
                <YAxis
                  domain={[minYValue, maxYValue]}
                  tickFormatter={(value) => `${value / 10000}만`}
                  tick={{ fontFamily: 'Roboto', fontSize: 12 }}
                />
                <RechartsTooltip content={<CustomTooltip />} />
                <Line
                  type="monotone"
                  dataKey="jejuHighPrice"
                  stroke="#8884d8"
                  dot={false}
                  isAnimationActive={false}
                />
                <Line
                  type="monotone"
                  dataKey="jejuAvgPrice"
                  stroke="#82ca9d"
                  dot={false}
                  isAnimationActive={false}
                />
                <Line
                  type="monotone"
                  dataKey="jejuLowPrice"
                  stroke="#ffc658"
                  dot={false}
                  isAnimationActive={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : displayMode === 'monthly' && chartType === 'graph' ? (
        // 월별 그래프
        <div style={{ marginBottom: '10px' }}>
          {/* Monthly 육지 & 제주도 데이터 */}
          <div style={{ position: 'relative' }}>
            <span
              style={{
                position: 'absolute',
                top: -10,
                left: 0,
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              육지
            </span>
          </div>
          <ResponsiveContainer width="100%" height={150}>
            <LineChart
              data={monthlyData}
              margin={{ top: 17, left: -15, right: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="month"
                tickFormatter={formatMonthWithSkipFirst}
                interval={0}
                tick={{ fontFamily: 'Roboto', fontSize: 12, fill: '#555' }}
              />
              <YAxis
                domain={['auto', (dataMax) => Math.ceil(dataMax * 1.01)]}
                tickFormatter={(value) => `${value / 10000}만`}
                tick={{ fontFamily: 'Roboto', fontSize: 12 }}
              />
              <RechartsTooltip content={<CustomTooltip />} />
              <Line
                type="monotone"
                dataKey="landMaxPrice"
                stroke="#8884d8"
                dot={false}
                isAnimationActive={false}
              />
              <Line
                type="monotone"
                dataKey="landAvgPrice"
                stroke="#82ca9d"
                dot={false}
                isAnimationActive={false}
              />
              <Line
                type="monotone"
                dataKey="landMinPrice"
                stroke="#ffc658"
                dot={false}
                isAnimationActive={false}
              />
            </LineChart>
          </ResponsiveContainer>
          <div style={{ position: 'relative' }}>
            <span
              style={{
                position: 'absolute',
                top: 10,
                left: 0,
                fontSize: '14px',
                fontWeight: 'bold',
              }}
            >
              제주도
            </span>
          </div>
          <ResponsiveContainer width="100%" height={170}>
            <LineChart
              data={monthlyData}
              margin={{ top: 40, left: -15, right: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="month"
                tickFormatter={formatMonthWithSkipFirst}
                interval={0}
                tick={{ fontFamily: 'Roboto', fontSize: 12, fill: '#555' }}
              />
              <YAxis
                domain={[minYValue, maxYValue]}
                tickFormatter={(value) => `${value / 10000}만`}
                tick={{ fontFamily: 'Roboto', fontSize: 12 }}
              />
              <RechartsTooltip content={<CustomTooltip />} />
              <Line
                type="monotone"
                dataKey="jejuMaxPrice"
                stroke="#8884d8"
                dot={false}
                isAnimationActive={false}
              />
              <Line
                type="monotone"
                dataKey="jejuAvgPrice"
                stroke="#82ca9d"
                dot={false}
                isAnimationActive={false}
              />
              <Line
                type="monotone"
                dataKey="jejuMinPrice"
                stroke="#ffc658"
                dot={false}
                isAnimationActive={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : displayMode === 'daily' && chartType === 'grid' ? (
        // 일별 테이블
        <div
          style={{
            fontFamily: 'Roboto, sans-serif',
            maxHeight: '320px',
            overflowY: 'auto',

            paddingTop: '20px',
          }}
        >
          <Typography variant="h6" style={{ padding: '10px ' }}>
            육지 일별 차트
          </Typography>
          <TableContainer
            component={Paper}
            style={{ marginLeft: '0px', width: '100%', overflowX: 'auto' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: '12px',
                      padding: '5px',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                  >
                    날짜
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '12px',
                      padding: '7px ',
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                    }}
                  >
                    최고가격
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '12px',
                      padding: '8px',
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                    }}
                  >
                    평균가격
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '12px',
                      padding: '8px',
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                    }}
                  >
                    최저가격
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recData.map(
                  ({ date, landHighPrice, landAvgPrice, landLowPrice }) => (
                    <TableRow key={date}>
                      <TableCell
                        style={{
                          fontSize: '12px',
                          padding: '0px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {formatDate(date)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '12px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {landHighPrice} 원
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '12px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {landAvgPrice} 원
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '12px',
                          padding: '6px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {landLowPrice} 원
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* 제주도 데이터 테이블 */}
          <Typography variant="h6" style={{ padding: '10px 0' }}>
            제주도 일별 차트
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: '11px',
                      padding: '5px 10px',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                  >
                    날짜
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '11px',
                      padding: '5px',
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                    }}
                  >
                    최고가격
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '11px',
                      padding: '5px',
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                    }}
                  >
                    평균가격
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '11px',
                      padding: '5px',
                      whiteSpace: 'nowrap',
                      textAlign: 'right',
                    }}
                  >
                    최저가격
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recData.map(
                  ({ date, jejuHighPrice, jejuAvgPrice, jejuLowPrice }) => (
                    <TableRow key={date}>
                      <TableCell
                        style={{
                          fontSize: '11px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {formatDate(date)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '11px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {jejuHighPrice} 원
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '11px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {jejuAvgPrice} 원
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '11px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {jejuLowPrice} 원
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        // 월별 테이블
        <div
          style={{
            fontFamily: 'Roboto, sans-serif',
            maxHeight: '320px',
            overflowY: 'auto',

            paddingTop: '20px',
          }}
        >
          <TableContainer component={Paper}>
            {/* 육지 데이터 테이블 */}
            <Typography variant="h6" style={{ padding: '10px' }}>
              육지 월별 차트
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: '12px',
                      padding: '5px',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                  >
                    날짜
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '12px',
                      padding: '5px',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                  >
                    최고가격
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '12px',
                      padding: '5px',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                  >
                    평균가격
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '12px',
                      padding: '5px',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                  >
                    최저가격
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getMonthlyData().map(
                  ({ month, landAvgPrice, landMaxPrice, landMinPrice }) => (
                    <TableRow key={month}>
                      <TableCell
                        style={{
                          fontSize: '12px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {formatDate(month)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '12px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {landMaxPrice} 원
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '12px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {landAvgPrice} 원
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '12px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {landMinPrice} 원
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>

            {/* 제주도 데이터 테이블 */}
            <Typography variant="h6" style={{ padding: '10px' }}>
              제주도 월별 차트
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: '12px',
                      padding: '5px',
                      whiteSpace: 'nowrap',
                      textAlign: 'center',
                    }}
                  >
                    날짜
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '12px',
                      padding: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    최고가격
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '12px',
                      padding: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    평균가격
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '12px',
                      padding: '5px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    최저가격
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getMonthlyData().map(
                  ({ month, jejuMaxPrice, jejuAvgPrice, jejuMinPrice }) => (
                    <TableRow key={month}>
                      <TableCell
                        style={{
                          fontSize: '12px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {formatDate(month)}
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '12px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {jejuMaxPrice} 원
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '12px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {jejuAvgPrice} 원
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: '12px',
                          padding: '5px',
                          whiteSpace: 'nowrap',
                          textAlign: 'right',
                        }}
                      >
                        {jejuMinPrice} 원
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
};

export default RecCard;
