import React, { useState, useEffect } from 'react';
import { useDataProvider } from '../context/DataProvider';
import InfoIcon from '@mui/icons-material/Info';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import CircularProgress from '@mui/material/CircularProgress';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// 데이터 구조 변환 함수 예시
const formatHourlyData = (hourly) => {
  const result = [];
  const inverter1Data = hourly[0];
  const inverter2Data = hourly[1];

  inverter1Data.forEach((entry, index) => {
    const dateStr = entry.date;
    const formattedDate = `${dateStr.slice(0, 4)}-${dateStr.slice(
      4,
      6
    )}-${dateStr.slice(6, 8)} ${dateStr.slice(8, 10)}:00`;

    result.push({
      fullDate: formattedDate,
      time: dateStr.slice(8, 10), // 시 정보만 x축에 사용할 수 있도록 추출
      inverter1Amount: entry.amount,
      inverter2Amount: inverter2Data[index] ? inverter2Data[index].amount : 0,
    });
  });

  return result;
};

const InverterCard = () => {
  const [displayMode, setDisplayMode] = useState('info');
  const [inverterData, setInverterData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const dataProvider = useDataProvider();
  const [lastUpdated, setLastUpdated] = useState('');

  useEffect(() => {
    const fetchData = async (retryCount = 3) => {
      try {
        const data = await dataProvider.getList({
          endpoint: 'plant/hyrogen/inverter',
        });
        setInverterData(data.log);
        const currentTime = new Date();
        setLastUpdated(
          `${currentTime.getHours()}시 ${currentTime.getMinutes()}분`
        );
        setGraphData(formatHourlyData(data.hourly));
      } catch (err) {
        console.error('Data fetching error:', err.message);

        if (retryCount > 0) {
          console.log(`Retrying... Attempts left: ${retryCount - 1}`);
          // 타이머를 이용해 일정 시간 후 재시도
          setTimeout(() => {
            fetchData(retryCount - 1); // 재시도
          }, 1000); // 1초 후 재시도
        } else {
          console.error('Failed to fetch data after multiple attempts');
        }
      }
    };
    fetchData();
  }, [dataProvider]);

  const handleButtonClick = (mode) => {
    setDisplayMode(mode);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { fullDate, inverter1Amount, inverter2Amount } = payload[0].payload;

      // 날짜 형식 변환
      const formattedDate =
        fullDate.replace(' ', '일 ').replace('-', '년 ').replace('-', '월 ') +
        '시';

      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '5px',
            fontSize: '13px',
          }}
        >
          <p style={{ margin: 0 }}>{formattedDate}</p>
          <p style={{ margin: 0 }}>인버터 1 발전량: {inverter1Amount} kWh</p>
          <p style={{ margin: 0 }}>인버터 2 발전량: {inverter2Amount} kWh</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '10px',
        }}
      >
        <button
          onClick={() => handleButtonClick('info')}
          style={{
            padding: '1px 3.5px',
            marginRight: '5px',
            backgroundColor: displayMode === 'info' ? '#007BFF' : '#ddd',
            color: displayMode === 'info' ? '#fff' : '#000',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          <InfoIcon />
        </button>
        <button
          onClick={() => handleButtonClick('graph')}
          style={{
            padding: '1px 3.5px',
            backgroundColor: displayMode === 'graph' ? '#007BFF' : '#ddd',
            color: displayMode === 'graph' ? '#fff' : '#000',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          <ShowChartIcon />
        </button>
      </div>

      <div style={{ marginTop: '0px', width: '102%' }}>
        {displayMode === 'info' ? (
          <TableContainer component={Paper} style={{ overflowX: 'auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: {
                        xs: '14px',
                        sm: '12px',
                        md: '10px',
                        lg: '11px',
                      },
                      padding: '1px',
                      textAlign: 'center',
                    }}
                  >
                    번호
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: {
                        xs: '14px',
                        sm: '12px',
                        md: '10px',
                        lg: '11px',
                      },
                      padding: '1px',
                      textAlign: 'center',
                    }}
                  >
                    출력
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: {
                        xs: '14px',
                        sm: '12px',
                        md: '10px',
                        lg: '11px',
                      },
                      padding: '1px',
                      textAlign: 'center',
                    }}
                  >
                    발전시간
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: {
                        xs: '14px',
                        sm: '12px',
                        md: '10px',
                        lg: '11px',
                      },
                      padding: '1px',
                      textAlign: 'center',
                    }}
                  >
                    일발전
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: {
                        xs: '14px',
                        sm: '12px',
                        md: '10px',
                        lg: '11px',
                      },
                      padding: '1px',
                      textAlign: 'center',
                    }}
                  >
                    상태
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: {
                        xs: '14px',
                        sm: '12px',
                        md: '10px',
                        lg: '11px',
                      },
                      padding: '1px',
                      textAlign: 'center',
                    }}
                  >
                    통신
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inverterData.map((log) => (
                  <TableRow key={log.inverterId}>
                    <TableCell
                      sx={{
                        fontSize: {
                          xs: '14px', // 작은 화면
                          sm: '12px', // 중간 화면
                          md: '10px', // 일반 화면
                          lg: '11px', // 큰 화면
                        },
                        padding: '2px',
                        textAlign: 'center',
                      }}
                    >
                      {log.inverterId === 9674
                        ? '인버터1'
                        : log.inverterId === 9675
                        ? '인버터2'
                        : 'N/A'}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: {
                          xs: '14px', // 작은 화면
                          sm: '12px', // 중간 화면
                          md: '10px', // 일반 화면
                          lg: '11px', // 큰 화면
                        },
                        padding: '2px',
                        textAlign: 'center',
                      }}
                    >
                      {log.outputPower || 'N/A'} kW
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: {
                          xs: '14px', // 작은 화면
                          sm: '12px', // 중간 화면
                          md: '10px', // 일반 화면
                          lg: '11px', // 큰 화면
                        },
                        padding: '2px',
                        textAlign: 'center',
                      }}
                    >
                      {log.totalTime || 'N/A'} h
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: {
                          xs: '14px', // 작은 화면
                          sm: '12px', // 중간 화면
                          md: '10px', // 일반 화면
                          lg: '11px', // 큰 화면
                        },
                        padding: '2px',
                        textAlign: 'center',
                      }}
                    >
                      {log.totalAmount || 'N/A'} kWh
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: {
                          xs: '14px', // 작은 화면
                          sm: '12px', // 중간 화면
                          md: '10px', // 일반 화면
                          lg: '11px', // 큰 화면
                        },
                        padding: '2px',
                        textAlign: 'center',
                      }}
                    >
                      {log.status === 'active' ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <CircularProgress
                            size={15}
                            style={{ marginRight: '5px' }}
                          />
                          <span>가동</span>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <WarningAmberIcon
                            color="warning"
                            style={{ marginRight: '5px' }}
                          />
                          <span>중단</span>
                        </div>
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: {
                          xs: '14px', // 작은 화면
                          sm: '12px', // 중간 화면
                          md: '10px', // 일반 화면
                          lg: '11px', // 큰 화면
                        },
                        padding: '2px',
                        textAlign: 'center',
                      }}
                    >
                      {lastUpdated}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              data={graphData}
              margin={{ top: 20, right: 10, left: -10, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="time"
                tickFormatter={(value) => `${value}시`}
                interval={1}
                tick={{ fontFamily: 'Roboto', fontSize: 12 }}
              />
              <YAxis
                domain={[0, (dataMax) => Math.ceil(dataMax * 1.1)]}
                tickFormatter={(value) => `${value} kWh`}
                tick={{ fontFamily: 'Roboto', fontSize: 12 }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Line
                type="monotone"
                dataKey="inverter1Amount"
                stroke="#8884d8"
                dot={false}
                isAnimationActive={false}
                name="인버터 1 발전량"
              />
              <Line
                type="monotone"
                dataKey="inverter2Amount"
                stroke="#82ca9d"
                dot={false}
                isAnimationActive={false}
                name="인버터 2 발전량"
              />
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default InverterCard;
