import React from "react";
import { Box, CardContent, Divider, Typography } from "@mui/material";

const GenerationInfoCard = () => {
  const infoItems = [
    { title: "사업주", description: "이여진 (010-7293-8305)" },
    { title: "안전관리자", description: "정보 없음" },
    { title: "시공사", description: "정보 없음" },
    { title: "주소", description: "대구 달서구 갈산동 193" },
    { title: "인버터", description: "Keumbee ST20K : 1개" },
    { title: "모듈", description: "정보 없음" },
  ];

  return (
    <CardContent sx={{ flexGrow: 1 }}>
      {infoItems.map((item, index) => (
        <React.Fragment key={index}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "row", sm: "row" }, // row로 고정
              justifyContent: "space-between",
              alignItems: "center",
              py: 0.1,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontWeight: "bold",
                fontFamily: "Roboto, sans-serif",
                fontSize: "16px",
                color: "#333",
                whiteSpace: "nowrap", // 한 줄에 유지
                overflow: "hidden",
                textOverflow: "ellipsis", // 글씨가 넘칠 경우 생략
                flex: 1,
                mr: 0, // 제목과 설명 사이 간격 조절
              }}
            >
              {item.title}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontFamily: "Roboto, sans-serif",
                fontSize: "16px",
                color: "#555",
                whiteSpace: "nowrap", // 한 줄에 유지
                overflow: "hidden",
                textOverflow: "ellipsis", // 글씨가 넘칠 경우 생략
                flex: 2,
                textAlign: "right",
              }}
            >
              {item.description}
            </Typography>
          </Box>
          {/* 각 항목들 사이에 줄 그리기, 마지막 항목 제외 */}
          {index < infoItems.length - 1 && <Divider sx={{ my: 1 }} />}
        </React.Fragment>
      ))}
    </CardContent>
  );
};

export default GenerationInfoCard;
