import React, { useState, useEffect } from 'react';
import { useDataProvider } from '../context/DataProvider';
import GridViewIcon from '@mui/icons-material/GridView';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// 데이터 구조 변환 함수
const formatData = (todayData, yesterdayData) => {
  const result = [];
  let todayAccumulated = 0;
  let yesterdayAccumulated = 0;
  const maxLength = Math.max(todayData.length, yesterdayData.length); // 두 데이터의 최대 길이

  for (let i = 0; i < maxLength; i++) {
    const todayEntry = todayData[i] || { amount: null, sk: '' }; // 오늘 데이터가 없으면 null 설정
    const yesterdayEntry = yesterdayData[i] || { amount: 0, sk: '' }; // 어제 데이터는 0으로 설정

    // 금일 데이터가 존재할 경우 누적 계산
    if (todayEntry.amount !== null) {
      todayAccumulated += todayEntry.amount;
    }

    yesterdayAccumulated += yesterdayEntry.amount;

    const dateStr = todayEntry.sk || yesterdayEntry.sk; // sk는 오늘이나 어제 데이터에서 가져옴
    const formattedDate =
      dateStr.length > 0
        ? `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(
            6,
            8
          )} ${dateStr.slice(8, 10)}:00`
        : '';

    result.push({
      fullDate: formattedDate,
      time: dateStr.slice(8, 10), // 시 정보만 x축에 사용할 수 있도록 추출
      todayAmount: todayEntry.amount, // 오늘 데이터가 없으면 null
      yesterdayAmount: yesterdayEntry.amount,
      todayAccumulated,
      yesterdayAccumulated,
    });
  }

  return result;
};

const DailyGenerationCard = () => {
  const [displayMode, setDisplayMode] = useState('graph');
  const [tableData, setTableData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const dataProvider = useDataProvider();

  const [yesterdayTotal, setYesterdayTotal] = useState(0);
  const [yesterdayData, setYesterdayData] = useState([]); // yesterdayData 추가

  const [todayTime, setTodayTime] = useState(0); // 오늘 발전 시간
  const [yesterdayTime, setYesterdayTime] = useState(0); // 어제 발전 시간

  useEffect(() => {
    const fetchData = async (retryCount = 3) => {
      try {
        const data = await dataProvider.getList({
          endpoint: 'plant/hyrogen/generation/daily',
        });
        const todayData = data.today.hourly;
        const yesterdayDataFetched = data.yesterday.hourly; // 어제 데이터 가져오기

        // API 데이터에서 시간 값을 가져옵니다.
        setTodayTime(data.today.time); // 오늘 발전 시간 설정
        setYesterdayTime(data.yesterday.time); // 어제 발전 시간 설정

        // 나머지 데이터 설정
        setYesterdayData(data.yesterday.hourly);
        setYesterdayTotal(data.yesterday.total);

        // 발전 시간 범위 (05시부터 19시)
        const generationHours = Array.from({ length: 15 }, (_, i) => i + 5); // 5~19시 배열

        const updatedTodayData = todayData.map((entry) => {
          const entryHour = parseInt(entry.sk.slice(8, 10), 10);

          // 현재 시간이 발전 시간 범위 내에 있고, 데이터가 0인 경우
          if (generationHours.includes(entryHour) && entry.amount === 0) {
            const previousEntry = todayData.find(
              (prev) => parseInt(prev.sk.slice(8, 10), 10) === entryHour - 1
            );
            return {
              ...entry,
              amount: previousEntry ? previousEntry.amount : 0, // 이전 데이터로 대체
            };
          }
          return entry; // 원래 데이터 유지
        });

        setTableData(updatedTodayData);
        setGraphData(formatData(updatedTodayData, yesterdayDataFetched)); // 수정된 어제 데이터 사용
      } catch (err) {
        console.error('Data fetching error:', err.message);

        if (retryCount > 0) {
          console.log(`Retrying... Attempts left: ${retryCount - 1}`);
          // 타이머를 이용해 일정 시간 후 재시도
          setTimeout(() => {
            fetchData(retryCount - 1); // 재시도
          }, 1000); // 1초 후 재시도
        } else {
          console.error('Failed to fetch data after multiple attempts');
        }
      }
    };

    fetchData();
  }, [dataProvider]);

  const getMaxTime = (data) => {
    const maxTime = data.reduce((max, entry) => {
      const entryHour = parseInt(entry.sk.slice(8, 10), 10);
      return entryHour > max ? entryHour : max;
    }, 0);
    return maxTime;
  };

  const handleButtonClick = (mode) => {
    setDisplayMode(mode);
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const { fullDate, todayAmount, yesterdayAmount } = payload[0].payload;

      // '년', '월', '일' 제거하고 시간만 가져오기
      const hoursOnly = fullDate.split(' ')[1].split(':')[0]; // 'HH:mm:ss' 형식에서 시간만 가져오기

      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '16px',
            fontSize: '16px',
          }}
        >
          <p style={{ margin: 0 }}>{`${hoursOnly} 시`}</p> {/* 시간만 표시 */}
          <p style={{ margin: 0 }}>금일 발전량: {todayAmount} kWh</p>
          <p style={{ margin: 0 }}>전일 발전량: {yesterdayAmount} kWh</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '10px',
        }}
      >
        <button
          onClick={() => handleButtonClick('graph')}
          style={{
            padding: '1px 3.5px',
            marginRight: '5px',
            backgroundColor: displayMode === 'graph' ? '#007BFF' : '#ddd',
            color: displayMode === 'graph' ? '#fff' : '#000',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            zIndex: 10,
          }}
        >
          <ShowChartIcon />
        </button>
        <button
          onClick={() => handleButtonClick('grid')}
          style={{
            padding: '1px 3.5px',
            backgroundColor: displayMode === 'grid' ? '#007BFF' : '#ddd',
            color: displayMode === 'grid' ? '#fff' : '#000',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            zIndex: 10,
          }}
        >
          <GridViewIcon />
        </button>
      </div>

      <div style={{ marginTop: '0px', width: '100%' }}>
        {displayMode === 'grid' ? (
          <TableContainer
            component={Paper}
            style={{ maxHeight: 290, overflowY: 'auto' }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontSize: '16px',
                      padding: '3px',
                      textAlign: 'center',
                    }}
                  >
                    시간
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '16px',
                      padding: '3px',
                      textAlign: 'center',
                    }}
                  >
                    금일 발전량
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '16px',
                      padding: '3px',
                      textAlign: 'center',
                    }}
                  >
                    누적 발전량
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((log, index) => (
                  <TableRow key={index}>
                    <TableCell
                      style={{
                        fontSize: '13px',
                        padding: '3px',
                        textAlign: 'center',
                      }}
                    >
                      {log.sk.slice(8, 10)}시
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: '13px',
                        padding: '3px',
                        textAlign: 'center',
                      }}
                    >
                      {log.amount} kWh
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: '13px',
                        padding: '3px',
                        textAlign: 'center',
                      }}
                    >
                      {graphData[index]?.todayAccumulated} kWh
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <>
            <ResponsiveContainer width="105%" height={185}>
              <LineChart
                data={graphData}
                margin={{ top: 10, right: 15, left: -10, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="time"
                  domain={[0, getMaxTime(yesterdayData)]} // 어제 데이터의 최대 시간 반영
                  interval={1}
                  tickFormatter={(value) => `${value}시`}
                  tick={{ fontFamily: 'Roboto', fontSize: 12 }}
                />
                <YAxis
                  domain={[0, (dataMax) => Math.ceil(dataMax * 1.1)]} // 최대값을 소수점 없이 10% 더 크게 설정
                  tickFormatter={(value) => `${value} kWh`}
                  tick={{ fontFamily: 'Roboto', fontSize: 12 }}
                />

                <Tooltip content={<CustomTooltip />} />
                {graphData.length > 0 && (
                  <Line
                    type="monotone"
                    dataKey="todayAmount"
                    stroke="#8884d8"
                    strokeWidth={2}
                    dot={false}
                    isAnimationActive={false}
                  />
                )}
                {graphData.length > 0 && (
                  <Line
                    type="monotone"
                    dataKey="yesterdayAmount"
                    stroke="#82ca9d"
                    strokeWidth={2}
                    dot={false}
                    isAnimationActive={false}
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: '1px', // 텍스트 간 간격을 줄임
                marginTop: '25px',
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#555',
                  textAlign: 'right',
                  margin: 0, // 추가 여백을 제거
                }}
              >
                금일 발전량:{' '}
                {graphData.reduce((acc, entry) => acc + entry.todayAmount, 0)}{' '}
                kWh
              </p>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#555',
                  textAlign: 'right',
                  margin: 0,
                }}
              >
                전일 발전량: {yesterdayTotal} kWh
              </p>
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  color: '#555',
                  textAlign: 'right',
                  margin: 0,
                }}
              >
                발전시간(전일): {todayTime} ({yesterdayTime}) 시간
              </p>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DailyGenerationCard;
