import React, { useEffect, useState, useCallback } from 'react';
import { Typography, Box, Button } from '@mui/material';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import AirIcon from '@mui/icons-material/Air';
import OpacityIcon from '@mui/icons-material/Opacity';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import CloudIcon from '@mui/icons-material/Cloud';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import NorthEastIcon from '@mui/icons-material/NorthEast';
import EastIcon from '@mui/icons-material/East';
import SouthEastIcon from '@mui/icons-material/SouthEast';
import SouthIcon from '@mui/icons-material/South';
import SouthWestIcon from '@mui/icons-material/SouthWest';
import WestIcon from '@mui/icons-material/West';
import NorthWestIcon from '@mui/icons-material/NorthWest';
import NorthIcon from '@mui/icons-material/North';
import NightsStayIcon from '@mui/icons-material/NightsStay';
import { useDataProvider } from '../context/DataProvider';

const WeatherCard = ({ inverterId }) => {
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dataProvider = useDataProvider();

  const fetchWeatherData = useCallback(
    async (date) => {
      const params = {
        plantId: 'hyrogen',
        inverterId: inverterId,
        date: date,
      };

      try {
        const response = await dataProvider.getList({
          endpoint: 'info/weather',
          params,
        });
        console.log('Weather Data Response:', response);
        setWeatherData(response);
        setError(null);
        return true;
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('데이터를 불러오는 중 오류가 발생했습니다.');
        return false;
      }
    },
    [dataProvider, inverterId]
  ); // dataProvider와 inverterId를 의존성 배열에 추가

  const fetchData = useCallback(async () => {
    setLoading(true);
    const now = new Date();

    const formattedCurrentDate = `${now.getFullYear()}${String(
      now.getMonth() + 1
    ).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}${String(
      now.getHours()
    ).padStart(2, '0')}00`;

    let currentDataSuccess = await fetchWeatherData(formattedCurrentDate);

    if (!currentDataSuccess) {
      now.setHours(now.getHours() - 1);
      const formattedPreviousDate = `${now.getFullYear()}${String(
        now.getMonth() + 1
      ).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}${String(
        now.getHours()
      ).padStart(2, '0')}00`;
      await fetchWeatherData(formattedPreviousDate);
    }

    setLoading(false);
  }, [fetchWeatherData]); // fetchWeatherData를 의존성 배열에 추가

  useEffect(() => {
    fetchData(); // fetchData 호출
  }, [fetchData]); // fetchData를 의존성 배열에 추가

  const refreshData = () => {
    setWeatherData(null);
    setError(null);
    fetchData();
  };

  const getWeatherIcon = (sky) => {
    const currentHour = new Date().getHours();
    if (sky >= 1 && sky <= 5) {
      return currentHour >= 19 || currentHour < 6 ? (
        <NightsStayIcon sx={{ color: '#fbc02d', fontSize: 40 }} />
      ) : (
        <WbSunnyIcon sx={{ color: '#ffeb3b', fontSize: 40 }} />
      );
    } else if (sky >= 6 && sky <= 8) {
      return <CloudIcon sx={{ color: '#90a4ae', fontSize: 40 }} />;
    } else if (sky >= 9 && sky <= 10) {
      return <CloudOffIcon sx={{ color: '#546e7a', fontSize: 40 }} />;
    }
    return null;
  };

  const getWindDirectionIcon = (windDir) => {
    const directionMap = {
      N: <NorthIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      NNE: <NorthEastIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      NE: <NorthEastIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      ENE: <NorthEastIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      E: <EastIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      ESE: <SouthEastIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      SE: <SouthEastIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      S: <SouthIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      SSE: <SouthEastIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      SSW: <SouthWestIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      SW: <SouthWestIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      WSW: <SouthWestIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      W: <WestIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      WNW: <NorthWestIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      NW: <NorthWestIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
      NNW: <NorthWestIcon sx={{ color: '#66bb6a', fontSize: 35 }} />,
    };

    return (
      directionMap[windDir] || (
        <NorthIcon sx={{ color: '#66bb6a', fontSize: 40 }} />
      )
    );
  };

  const windDirectionText = (windDir) => {
    const directionText = {
      N: '북',
      NNE: '북북동',
      NE: '북동',
      ENE: '동북동',
      E: '동',
      ESE: '동남동',
      SE: '남동',
      SSE: '남남동',
      S: '남',
      SSW: '남서남',
      SW: '남서',
      WSW: '서남서',
      W: '서',
      WNW: '서북서',
      NW: '북서',
      NNW: '북북서',
    };

    return directionText[windDir.toUpperCase()] || '북';
  };

  return (
    <Box
      sx={{
        height: 350,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        p: 3,
      }}
    >
      {loading ? (
        <Typography variant="h6">로딩 중...</Typography>
      ) : error ? (
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant="h6" color="error">
            {error}
          </Typography>
          <Button variant="contained" color="primary" onClick={refreshData}>
            새로고침
          </Button>
        </Box>
      ) : weatherData ? (
        <Box sx={{ textAlign: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 2,
            }}
          >
            {getWeatherIcon(parseInt(weatherData.sky, 10))}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <ThermostatIcon sx={{ color: '#ff7043', fontSize: 35, mr: 2 }} />
            <Typography variant="body1" sx={{ fontSize: '18px' }}>
              <strong>온도:</strong> {weatherData.temp}°C
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <OpacityIcon sx={{ color: '#42a5f5', fontSize: 35, mr: 2 }} />
            <Typography variant="body1" sx={{ fontSize: '18px' }}>
              <strong>습도:</strong> {weatherData.humidity}%
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.7 }}>
            <AirIcon sx={{ color: '#29b6f6', fontSize: 35, mr: 2 }} />
            <Typography variant="body1" sx={{ fontSize: '18px' }}>
              <strong>풍속:</strong> {weatherData.windSpeed}m/s
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            {getWindDirectionIcon(weatherData.windDir)}
            <Typography variant="body1" sx={{ fontSize: '18px', ml: 2 }}>
              <strong>풍향:</strong> {windDirectionText(weatherData.windDir)}
            </Typography>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default WeatherCard;
