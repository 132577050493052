import React, { useState, useEffect } from 'react';
import { useDataProvider } from '../context/DataProvider';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';

const ModuleCard = () => {
  const [moduleData, setModuleData] = useState([]);
  const dataProvider = useDataProvider();

  useEffect(() => {
    const fetchData = async (retryCount = 3) => {
      try {
        const data = await dataProvider.getList({
          endpoint: 'plant/hyrogen/inverterInput',
        });
        setModuleData(data);
      } catch (err) {
        console.error('Data fetching error:', err.message);

        if (retryCount > 0) {
          console.log(`Retrying... Attempts left: ${retryCount - 1}`);
          // 타이머를 이용해 일정 시간 후 재시도
          setTimeout(() => {
            fetchData(retryCount - 1); // 재시도
          }, 1000); // 1초 후 재시도
        } else {
          console.error('Failed to fetch data after multiple attempts');
        }
      }
    };
    fetchData();
  }, [dataProvider]);

  return (
    <TableContainer component={Paper} style={{ marginTop: 0 }}>
      <Table>
        <TableBody>
          {moduleData.map((module) => (
            <React.Fragment key={module.inverterId}>
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold', fontSize: 15 }}
                  >
                    인버터 ID: {module.inverterId}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    fontSize: 15,
                    padding: '7px 8px',
                  }}
                >
                  전력
                </TableCell>
                <TableCell style={{ textAlign: 'right', padding: '7px 8px' }}>
                  {`${module.power} kW`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    fontSize: 15,
                    padding: '7px 8px',
                  }}
                >
                  전압
                </TableCell>
                <TableCell style={{ textAlign: 'right', padding: '7px 8px' }}>
                  {`${module.volt} V`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  style={{
                    fontWeight: 'bold',
                    fontSize: 15,
                    padding: '7px 8px',
                  }}
                >
                  전류
                </TableCell>
                <TableCell style={{ textAlign: 'right', padding: '7px 8px' }}>
                  {`${module.current} A`}
                </TableCell>
              </TableRow>
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ModuleCard;
