import React from 'react';
import { DataProvider, dataProvider } from './context/DataProvider';
import Router from './Router';

function App() {
  return (
    <DataProvider dataProvider={dataProvider}>
      <Router />
    </DataProvider>
  );
}

export default App;
