import React, { useEffect, useState } from 'react';
import { useDataProvider } from '../context/DataProvider';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// Tooltip에 '원'을 추가하는 함수
const CustomTooltip = ({ payload, label }) => {
  if (payload && payload.length) {
    const value = payload[0].value;

    // 날짜 형식을 'YYYYMMDD'에서 'YYYY년 M월 D일'로 변환하는 함수
    const formatDate = (dateString) => {
      const year = dateString.substring(0, 4);
      const month = parseInt(dateString.substring(4, 6), 10);
      const day = parseInt(dateString.substring(6, 8), 10);
      return `${year}년 ${month}월 ${day}일`;
    };

    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ddd',
        }}
      >
        <p>{`날짜: ${formatDate(label)}`}</p>
        <p>{`가격: ${value} 원`}</p>
      </div>
    );
  }
  return null;
};

// 날짜 형식을 'YYYYMMDD'에서 'MM-DD'로 변환하는 함수
const formatShortDate = (dateString) => {
  const month = dateString.substring(4, 6); // '09'
  const day = dateString.substring(6, 8); // '13'

  return `${month}-${day}`; // '09-13' 형식으로 반환
};

const SmpCard = () => {
  const [smpData, setSmpData] = useState([]);
  const dataProvider = useDataProvider(); // dataProvider 사용

  useEffect(() => {
    const fetchSmpData = async (retryCount = 3) => {
      try {
        const data = await dataProvider.getList({
          endpoint: 'info/smp',
        });
        setSmpData(data);
      } catch (err) {
        console.error('Data fetching error:', err.message);

        if (retryCount > 0) {
          console.log(`Retrying... Attempts left: ${retryCount - 1}`);
          // 타이머를 이용해 일정 시간 후 재시도
          setTimeout(() => {
            fetchSmpData(retryCount - 1); // 재시도
          }, 1000); // 1초 후 재시도
        } else {
          console.error('Failed to fetch data after multiple attempts');
        }
      }
    };

    fetchSmpData();
  }, [dataProvider]); // dataProvider가 변경되면 재실행

  // Y축의 최소 및 최대 값을 동적으로 계산
  const minYValue =
    Math.floor(Math.min(...smpData.map((d) => d.smp_land)) / 10) * 10;
  const maxYValue =
    Math.ceil(Math.max(...smpData.map((d) => d.smp_land)) / 10) * 10;

  return (
    <div>
      {/* 육지 데이터 차트 */}
      <div style={{ marginBottom: '10px' }}>
        {/* 육지 텍스트 */}
        <div style={{ position: 'relative' }}>
          <span
            style={{
              position: 'absolute',
              top: 15, // 위쪽으로 올림
              left: 0, // 왼쪽에 위치
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            육지
          </span>
        </div>
        <ResponsiveContainer
          width="100%"
          height={180}
          margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
        >
          <LineChart data={smpData} margin={{ top: 50, left: -30, right: 10 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={formatShortDate} // X축을 'MM-DD' 형식으로 표시
              tick={{ fontFamily: 'Roboto', fontSize: 12, fill: '#555' }}
            />
            <YAxis
              domain={['auto', (dataMax) => Math.ceil(dataMax * 1.05)]}
              tick={({ payload, x, y, textAnchor, stroke, fill }) => {
                const isTopTick = payload.value === maxYValue; // 최대값일 때
                const isMidTick = payload.value === (minYValue + maxYValue) / 2; // 중간값일 때
                return (
                  <text
                    x={x}
                    y={y}
                    dy={isTopTick ? -2 : isMidTick ? 5 : 5} // 최대값은 위로, 중간값은 아래로 조절
                    textAnchor={textAnchor}
                    fill={fill}
                    fontFamily="Roboto"
                    fontSize={12}
                  >
                    {payload.value}
                  </text>
                );
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="smp_land"
              stroke="#8884d8"
              strokeWidth={2}
              dot={false}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* 제주 데이터 차트 */}
      <div>
        {/* 제주도 텍스트 */}
        <div style={{ position: 'relative' }}>
          <span
            style={{
              position: 'absolute',
              top: 0, // 위쪽으로 올림
              left: 0, // 왼쪽에 위치
              fontSize: '14px',
              fontWeight: 'bold',
            }}
          >
            제주도
          </span>
        </div>
        <ResponsiveContainer
          width="100%"
          height={160}
          margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
        >
          <LineChart data={smpData} margin={{ top: 30, left: -30, right: 10 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="date"
              tickFormatter={formatShortDate} // X축을 'MM-DD' 형식으로 표시
              tick={{ fontFamily: 'Roboto', fontSize: 12, fill: '#555' }}
            />
            <YAxis
              domain={['auto', (dataMax) => Math.ceil(dataMax * 1.05)]}
              tick={({ payload, x, y, textAnchor, stroke, fill }) => {
                const isTopTick = payload.value === maxYValue; // 최대값일 때
                const isMidTick = payload.value === (minYValue + maxYValue) / 2; // 중간값일 때
                return (
                  <text
                    x={x}
                    y={y}
                    dy={isTopTick ? -2 : isMidTick ? 5 : 5} // 최대값은 위로, 중간값은 아래로 조절
                    textAnchor={textAnchor}
                    fill={fill}
                    fontFamily="Roboto"
                    fontSize={12}
                  >
                    {Math.round(payload.value)} {/* 소수점 제거 */}
                  </text>
                );
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="smp_jeju"
              stroke="#82ca9d"
              strokeWidth={2}
              dot={false}
              isAnimationActive={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default SmpCard;
