import React from 'react';
import { Card, CardContent, Box } from '@mui/material';

const CustomCard = ({ title, children }) => {
  return (
    <Card sx={{ display: 'flex', flexDirection: 'column', height: 420 }}>
      <Box
        sx={{
          backgroundColor: 'gray',
          color: 'white',
          p: 2,
          fontWeight: 'bold',
          fontSize: '16px',
        }}
      >
        {title}
      </Box>
      <CardContent sx={{ flexGrow: 1 }}>{children}</CardContent>
    </Card>
  );
};

export default CustomCard;
