import React, { useState, useEffect, useRef } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Box,
  Typography,
} from '@mui/material';
import axios from 'axios';

const GenerrorCard = () => {
  const [generrorData, setGenerrorData] = useState([]);
  const [isNarrowCard, setIsNarrowCard] = useState(false);
  const cardRef = useRef(null);

  useEffect(() => {
    const fetchErrorData = async (retryCount = 3) => {
      try {
        const response = await axios.get(
          'https://api.h2rogen.com/plant/hyrogen/error'
        );
        const errorData = response.data;

        const formattedData = errorData
          .map((error) => ({
            id: error.plantId,
            startTime: error.occuredAt,
            endTime: error.restoredAt,
            errType: error.errType,
          }))
          .sort((a, b) => new Date(b.startTime) - new Date(a.startTime));

        setGenerrorData(formattedData);
      } catch (error) {
        console.error('Error fetching Generror data:', error.message);

        if (retryCount > 0) {
          console.log(`Retrying... Attempts left: ${retryCount - 1}`);
          setTimeout(() => {
            fetchErrorData(retryCount - 1); // 재시도
          }, 1000); // 1초 후 재시도
        } else {
          console.error(
            'Failed to fetch Generror data after multiple attempts'
          );
        }
      }
    };

    fetchErrorData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (cardRef.current) {
        setIsNarrowCard(cardRef.current.offsetWidth <= 300);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: 340, overflowY: 'auto' }}
      ref={cardRef}
    >
      <Table>
        <TableBody>
          {generrorData.length > 0 ? (
            generrorData.map((error) => (
              <TableRow key={`${error.id}-${error.startTime}`}>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: isNarrowCard ? 'column' : 'row',
                      justifyContent: isNarrowCard ? 'center' : 'space-between',
                      alignItems: isNarrowCard ? 'center' : 'flex-start',
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                        인버터ID: {error.id}
                      </Typography>
                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                        발생 시간: {error.startTime}
                      </Typography>
                      <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                        해제 시간: {error.endTime}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: 'red',
                        color: 'white',
                        padding: '2px 12px',
                        borderRadius: '4px',
                        fontSize: '12px',
                        minWidth: '90px',
                        textAlign: 'center',
                        marginTop: '20px',
                        marginRight: -1,
                      }}
                    >
                      {error.errType}
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell style={{ textAlign: 'center' }}>
                데이터가 없습니다.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GenerrorCard;
