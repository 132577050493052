import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  CssBaseline,
  AppBar,
  Toolbar,
  Grid,
  Drawer,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; // 햄버거 아이콘

import WeatherCard from '../components/WeatherCard';
import RecCard from '../components/RecCard';
import SmpCard from '../components/SmpCard';
import MGenerationCard from '../components/MGenerationCard';
import YgenerationCard from '../components/YgenerationCard';
import GenerationInfoCard from '../components/GenerationInfoCard';
import InverterCard from '../components/InverterCard';
import CustomCard from '../components/CustomCard';
import ModuleCard from '../components/ModuleCard';
import LogoHorizon from '../images/hyrogen_logo_horizental.png';
import GenerrorCard from '../components/GenerrorCard';
import DailyGenerationCard from '../components/DailyGenerationCard';
// import CurrentGenerationStatusCard from '../components/CurrentGenerationStatusCard';
import { useNavigate } from 'react-router-dom';

const drawerWidth = 90; // 기본 사이드바 너비

const Dashboard = () => {
  const [weatherData] = useState(null);
  const [inverterId, setInverterId] = useState(9674); // 기본 inverterId

  const [error] = useState(null);

  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = useState(false); // 모바일 메뉴 상태
  const [windowWidth, setWindowWidth] = useState(window.innerWidth); // 창 너비 상태 관리
  const [anchorEl, setAnchorEl] = useState(null); // 드롭다운 메뉴의 앵커 엘리먼트
  const [selectedPlant, setSelectedPlant] = useState('발전소 1'); // 선택된 발전소 이름 상태

  const [anchorElDropdown, setAnchorElDropdown] = useState(null); // 드롭다운 메뉴의 앵커 엘리먼트
  const [selectedMenuItem, setSelectedMenuItem] =
    useState('[09674][대구]하이로젠1'); // 선택된 메뉴 항목

  const getCurrentDate = () => {
    const now = new Date();
    return now.toLocaleDateString('ko-KR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      const fetchUserData = async () => {
        try {
          const response = await axios.get('https://api.h2rogen.com/user/me', {
            headers: { Authorization: `Bearer ${token}` },
          });
          console.log('유저 정보:', response.data); // 유저 정보를 콘솔에 출력
        } catch (error) {
          console.error('유저 정보 가져오기 실패:', error);
        }
      };

      fetchUserData(); // 유저 정보 가져오기
    } else {
      navigate('/login', { replace: true }); // 토큰이 없으면 로그인 페이지로 이동
    }
  }, [navigate]);

  // 햄버거 메뉴 클릭 핸들러 (상단 햄버거 버튼)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen); // 모바일에서 메뉴 토글
  };

  const handleDropdownClick = (event) => {
    setAnchorElDropdown(event.currentTarget); // 클릭한 엘리먼트를 앵커로 설정
  };

  // 드롭다운 메뉴 닫기 핸들러
  const handleDropdownClose = () => {
    setAnchorElDropdown(null); // 앵커를 null로 설정하여 닫기
  };
  // 발전소 선택 핸들러
  const handlePlantSelect = (plantName) => {
    setSelectedPlant(plantName); // 선택된 발전소 이름 설정
    handleDropdownClose(); // 메뉴 닫기
  };

  // 드롭다운 메뉴 클릭 핸들러
  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget); // 클릭한 엘리먼트를 앵커로 설정
  };

  // 드롭다운 메뉴 닫기 핸들러
  const handleMenuClose = () => {
    setAnchorEl(null); // 앵커를 null로 설정하여 닫기
  };
  // 드롭다운 항목 선택 핸들러
  const handleMenuItemSelect = (menuItem, id) => {
    console.log('Selected menu item:', menuItem); // 선택된 메뉴 항목 로그
    setSelectedMenuItem(menuItem); // 선택된 항목으로 메뉴 이름 변경
    setInverterId(id); // 선택된 항목에 맞는 inverterId 설정
    handleMenuClose(); // 메뉴 닫기
  };

  // 창 크기 변화 감지하여 사이드바 자동 축소/확장 처리
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth <= 1200) {
        setMobileOpen(false); // 1200px 이하일 때 사이드바 숨김
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* 앱바 */}
      <AppBar
        position="fixed"
        sx={{
          width: '100%', // 상단 전체를 차지하도록 설정
          backgroundColor: 'white',
          boxShadow: 'none',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          top: 0,
          zIndex: 1201,
        }}
      >
        <Toolbar>
          <img
            src={LogoHorizon}
            alt="Hyrogen Logo"
            style={{
              width: '105px',
              marginLeft: '8px',
              height: 'auto',
              marginRight: '16px',
            }}
          />

          <Box display="flex" alignItems="center" sx={{ flexWrap: 'wrap' }}>
            {/* 현재 날짜 표시 */}
            <Typography
              variant="body1"
              sx={{ marginLeft: 0, color: 'black', whiteSpace: 'nowrap' }}
            >
              {getCurrentDate()} {/* 현재 날짜 표시 */}
            </Typography>

            {/* 마지막 업데이트 시간 표시 */}
            <Typography
              variant="body1"
              sx={{ marginLeft: 2, color: 'black', whiteSpace: 'nowrap' }}
            >
              {`데이터 업데이트: ${new Date().toLocaleTimeString()}`}
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex', // 수평으로 배치
              flexDirection: 'row', // 수평 방향 유지
              alignItems: 'center', // 수직 가운데 정렬
              justifyContent: 'flex-end', // 오른쪽 정렬
              flexGrow: 1, // 버튼 그룹이 오른쪽 끝으로 밀리도록 설정
            }}
          >
            <Button
              onClick={() => {
                localStorage.removeItem('token'); // 로그아웃 시 토큰 삭제
                navigate('/login', { replace: true }); // 로그인 페이지로 이동
              }}
              variant="outlined"
              sx={{ marginLeft: '8px' }}
            >
              로그아웃
            </Button>
          </Box>

          {/* 상단 햄버거 버튼 (사이드바 토글) */}
          <IconButton
            color="inherit"
            aria-label="toggle sidebar"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{
              ml: 0.5,
              zIndex: 1300,
              color: 'black',
              display: { xs: 'block', md: 'block', lg: 'none' },
            }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* 사이드바 */}
      <Drawer
        variant={windowWidth >= 1200 ? 'permanent' : 'temporary'} // 1200px 이상에서는 항상 보이도록
        open={windowWidth >= 1200 ? true : mobileOpen} // 모바일에서는 토글
        onClose={handleDrawerToggle} // 닫기 핸들러
        anchor={windowWidth >= 1200 ? 'left' : 'right'} // 1200px 이상일 때는 왼쪽, 이하일 때는 오른쪽에서 열림
        sx={{
          '& .MuiDrawer-paper': {
            width: drawerWidth, // 사이드바 너비 설정
          },
        }}
      >
        {/* 사이드바 내용 */}
        <Box
          sx={{
            flexGrow: 1,
            overflow: 'auto', // 내용이 넘칠 때 스크롤 가능
          }}
        >
          <Box sx={{ height: 64 }} /> {/* 빈 공간을 위한 Box */}
          <List>
            <ListItem button>
              <ListItemText primary="하이로젠" />
            </ListItem>
          </List>
        </Box>
      </Drawer>

      {/* 메인 콘텐츠 */}
      <Box
        sx={{
          p: 3,
          mt: 0,

          marginLeft: windowWidth >= 1200 ? `${drawerWidth}px` : '0px', // 1200px 이상에서는 사이드바만큼 여백
          transition: 'margin-left 0.3s', // 애니메이션 추가
        }}
      >
        <Toolbar />

        {/* 드롭다운 버튼 */}
        <Button
          onClick={handleDropdownClick}
          variant="contained"
          color="inherit"
          sx={{
            ml: 0,
            mt: 0,
            mb: 3,
            backgroundColor: 'white',
            border: '1px solid black',
            color: 'black',
            minWidth: '120px', // 버튼의 최소 너비 설정
          }}
        >
          {selectedPlant} {/* 선택된 발전소 이름 표시 */}
        </Button>
        <Menu
          anchorEl={anchorElDropdown}
          open={Boolean(anchorElDropdown)}
          onClose={handleDropdownClose}
        >
          <MenuItem
            sx={{
              display: 'flex', // 수평으로 배치
              flexDirection: 'row', // 수평 방향 유지
              alignItems: 'center', // 수직 가운데 정렬
              justifyContent: 'center', // 오른쪽 정렬
              minWidth: '120px', // 버튼의 최소 너비 설정
            }}
            onClick={() => handlePlantSelect(' 발전소 1')}
          >
            발전소 1
          </MenuItem>
        </Menu>

        {/* 드롭다운 메뉴 */}
        <Button
          onClick={handleMenuClick}
          variant="contained"
          color="inherit"
          sx={{
            ml: 3,
            mt: 0,
            mb: 3,
            backgroundColor: 'white',
            border: '1px solid black',
            color: 'black',
          }}
        >
          {selectedMenuItem} {/* 선택된 항목 표시 */}
        </Button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{
            zIndex: 1300,
            '& .MuiMenu-paper': {
              border: '1px solid black',
            },
          }}
        >
          <MenuItem
            onClick={() => handleMenuItemSelect('[09674][대구]하이로젠1', 9674)}
            sx={{
              minWidth: '180px', // 버튼의 최소 너비 설정
              color: 'black',
              '&:hover': { backgroundColor: '#f5f5f5' },
            }}
          >
            [09674][대구]하이로젠1
          </MenuItem>
          <MenuItem
            onClick={() => handleMenuItemSelect('[09675][대구]하이로젠2', 9675)}
            sx={{
              color: 'black',
              '&:hover': { backgroundColor: '#f5f5f5' },
            }}
          >
            [09675][대구]하이로젠2
          </MenuItem>
        </Menu>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <CustomCard title="발전소 정보">
              <GenerationInfoCard />
            </CustomCard>
          </Grid>

          {/* 현재 발전 상태 */}
          {/*<Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <CustomCard title="현재 발전 상태">
              <CurrentGenerationStatusCard />
            </CustomCard>
          </Grid> */}
          {/* 일 발전량 */}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <CustomCard title="일 발전량">
              <DailyGenerationCard />
            </CustomCard>
          </Grid>
          {/* MGenerationCard */}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <CustomCard title="월 발전량">
              <MGenerationCard inverterId={inverterId} />{' '}
              {/* inverterId를 props로 전달 */}
            </CustomCard>
          </Grid>

          {/* YgenerationCard */}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <CustomCard title="연 발전량">
              <YgenerationCard inverterId={inverterId} />{' '}
            </CustomCard>
          </Grid>

          {/* WeatherCard */}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <CustomCard title="기상 정보">
              <WeatherCard
                weatherData={weatherData}
                error={error}
                inverterId={inverterId}
              />{' '}
            </CustomCard>
          </Grid>

          {/* RecCard */}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <CustomCard title="REC 시장 정보">
              <RecCard />
            </CustomCard>
          </Grid>

          {/* SMP 시장 정보 */}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <CustomCard title="SMP 시장 정보">
              <SmpCard />
            </CustomCard>
          </Grid>

          {/* 인버터 정보 */}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <CustomCard title="인버터 정보">
              <InverterCard />
            </CustomCard>
          </Grid>

          {/* 모듈(인버터 DC입력) */}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <CustomCard title="모듈(인버터 DC입력)">
              <ModuleCard />
            </CustomCard>
          </Grid>

          {/* 발전소 오류 정보 */}
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2.4}>
            <CustomCard title="발전소 오류 정보">
              <GenerrorCard />
            </CustomCard>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;
