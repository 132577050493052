import React, { useEffect, useState } from 'react';
import { useDataProvider } from '../context/DataProvider';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import GridViewIcon from '@mui/icons-material/GridView';

const MgenerationCard = () => {
  const { getList } = useDataProvider();

  const [tableData, setTableData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [totalAmountThisMonth, setTotalAmountThisMonth] = useState(0);
  const [totalAmountLastMonth, setTotalAmountLastMonth] = useState(0);
  const [avgTimeThisMonth, setAvgTimeThisMonth] = useState(0);
  const [avgTimeLastMonth, setAvgTimeLastMonth] = useState(0);
  const [displayMode, setDisplayMode] = useState('graph'); // 기본 모드 설정

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getList({
          endpoint: 'plant/hyrogen/generation/monthly',
          params: {},
        });

        // 현재 월과 이전 월의 마지막 날짜 구하기
        const currentMonth = new Date().getMonth(); // 0-11 (0: January, 11: December)
        const currentYear = new Date().getFullYear();

        const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1; // 이전 월 (1월이면 12월로)

        // 각 월의 마지막 날짜 계산
        const getLastDateOfMonth = (year, month) =>
          new Date(year, month + 1, 0).getDate(); // 해당 월의 마지막 날짜

        const currentMonthLastDate = getLastDateOfMonth(
          currentYear,
          currentMonth
        );
        const lastMonthLastDate = getLastDateOfMonth(currentYear, lastMonth);

        // x축 날짜 배열 생성 (현재 월과 이전 월의 날짜 범위)
        const totalDays = Math.max(currentMonthLastDate, lastMonthLastDate); // 두 월 중 더 큰 날짜로 X축 범위 설정
        const dates = Array.from({ length: totalDays }, (_, i) =>
          (i + 1).toString().padStart(2, '0')
        );

        // 10월과 11월의 데이터를 합침
        const graphData = dates.map((date) => {
          const thisMonthItem = data.thisMonth.data.find(
            (item) => item.date.slice(6) === date
          ); // 현재 월 데이터
          const lastMonthItem = data.lastMonth.data.find(
            (item) => item.date.slice(6) === date
          ); // 이전 월 데이터

          // 미래의 날짜는 null 처리
          const currentDay = new Date().getDate();
          if (parseInt(date) > currentDay) {
            return {
              date,
              thisMonthAmount: null, // 금월 데이터는 null 처리
              lastMonthAmount: lastMonthItem ? lastMonthItem.amount : 0, // 전월 데이터는 표시
            };
          }

          return {
            date,
            thisMonthAmount: thisMonthItem ? thisMonthItem.amount : 0,
            lastMonthAmount: lastMonthItem ? lastMonthItem.amount : 0,
          };
        });

        // 데이터 상태 업데이트
        setTableData(data.thisMonth.data);
        setGraphData(graphData); // 10월과 11월을 합쳐서 설정
        setTotalAmountThisMonth(data.thisMonth.totalAmount || 0); // null 처리
        setTotalAmountLastMonth(data.lastMonth.totalAmount || 0); // null 처리
        setAvgTimeThisMonth(data.thisMonth.avgTime || 0);
        setAvgTimeLastMonth(data.lastMonth.avgTime || 0);
      } catch (err) {
        console.error('Data fetching error:', err.message);
      }
    };

    fetchData();
  }, [getList]);

  // 툴팁 렌더링 시 두 달의 발전량을 각각 표시하도록 처리
  const CustomTooltip = ({ active, payload }) => {
    const [currentDate, setCurrentDate] = useState(new Date()); // 현재 날짜
    const currentDay = currentDate.getDate(); // 오늘 날짜 (일)

    useEffect(() => {
      // 현재 날짜를 계속 추적 (컴포넌트 렌더링 시점에 맞춰 업데이트)
      setCurrentDate(new Date());
    }, []);

    if (active && payload && payload.length) {
      const date = parseInt(payload[0]?.payload?.date, 10); // 날짜를 정수로 변환
      const thisMonthAmount = payload[0]?.payload?.thisMonthAmount || null; // 금월 데이터 (기본값 null)
      const lastMonthAmount = payload[0]?.payload?.lastMonthAmount || null; // 전월 데이터 (기본값 null)

      const tooltipStyle = {
        backgroundColor: 'white',
        color: '#333',
        padding: '10px',
        borderRadius: '5px',
      };

      if (date > currentDay) {
        return (
          <div className="custom-tooltip" style={tooltipStyle}>
            <p>{`날짜: ${date}일`}</p>
            {lastMonthAmount !== null && (
              <p>{`전월 : ${lastMonthAmount} kWh`}</p>
            )}
          </div>
        );
      }

      return (
        <div className="custom-tooltip" style={tooltipStyle}>
          <p>{`날짜: ${date}일`}</p>
          {thisMonthAmount !== null && <p>{`금월 : ${thisMonthAmount} kWh`}</p>}
          {lastMonthAmount !== null && <p>{`전월 : ${lastMonthAmount} kWh`}</p>}
        </div>
      );
    }

    return null;
  };

  const formatDate = (dateString) => {
    // 날짜 문자열의 마지막 두 자리만 추출
    const day = parseInt(dateString.slice(-2), 10); // '01' 같은 경우 1로 변환
    return `${day}일`;
  };

  const handleButtonClick = (mode) => {
    setDisplayMode(mode);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '10px',
        }}
      >
        <button
          onClick={() => handleButtonClick('graph')}
          style={{
            padding: '1px 3.5px',
            marginRight: '5px',
            backgroundColor: displayMode === 'graph' ? '#007BFF' : '#ddd',
            color: displayMode === 'graph' ? '#fff' : '#000',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            zIndex: 10,
          }}
        >
          <ShowChartIcon />
        </button>
        <button
          onClick={() => handleButtonClick('grid')}
          style={{
            padding: '1px 3.5px',
            backgroundColor: displayMode === 'grid' ? '#007BFF' : '#ddd',
            color: displayMode === 'grid' ? '#fff' : '#000',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            zIndex: 10,
          }}
        >
          <GridViewIcon />
        </button>
      </div>

      {displayMode === 'graph' && (
        <ResponsiveContainer width="105%" height={185}>
          <LineChart
            data={graphData}
            margin={{ top: 10, right: 15, left: 0, bottom: 5 }}
          >
            <XAxis
              dataKey="date"
              interval={2}
              tickFormatter={(value) => {
                const monthDay = value.toString();
                if (monthDay.length === 2) {
                  const day = monthDay.slice(0);
                  return `${parseInt(day, 10)}일`;
                }
                return value;
              }}
              tick={{ fontFamily: 'Roboto', fontSize: 12 }}
            />

            <YAxis
              domain={[0, (dataMax) => Math.ceil(dataMax * 1.1)]}
              tickFormatter={(value) => `${value} kWh`}
              tick={{ fontFamily: 'Roboto', fontSize: 12 }}
            />
            <Tooltip
              content={<CustomTooltip />}
              labelFormatter={(label) => {
                const date = label.toString();
                const month = date.slice(0, 2);
                const day = date.slice(2);
                return `${month}월 ${day}일`;
              }}
              itemStyle={{ color: '#8884d8' }}
            />

            <CartesianGrid strokeDasharray="3 3" />
            {graphData.length > 0 && (
              <Line
                type="monotone"
                dataKey="thisMonthAmount"
                stroke="#8884d8"
                strokeWidth={2}
                dot={false}
                isAnimationActive={false}
              />
            )}
            {graphData.length > 0 && (
              <Line
                type="monotone"
                dataKey="lastMonthAmount"
                stroke="#82ca9d"
                strokeWidth={2}
                dot={false}
                isAnimationActive={false}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      )}

      {displayMode === 'grid' && (
        <TableContainer
          component={Paper}
          style={{ marginTop: '10px', maxHeight: '320px', overflowY: 'auto' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontSize: '16px',
                    padding: '3px',
                    textAlign: 'center',
                  }}
                >
                  일별
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '16px',
                    padding: '3px',
                    textAlign: 'center',
                  }}
                >
                  발전량 (kWh)
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '16px',
                    padding: '3px',
                    textAlign: 'center',
                  }}
                >
                  일평균발전시간 (시간)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{
                      fontSize: '13px',
                      padding: '3px',
                      textAlign: 'center',
                    }}
                  >
                    {formatDate(row.date)}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '13px',
                      padding: '3px',
                      textAlign: 'center',
                    }}
                  >
                    {row.amount.toLocaleString()} kWh
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '13px',
                      padding: '3px',
                      textAlign: 'center',
                    }}
                  >
                    {row.time} 시간
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {displayMode === 'graph' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: '1px',
            marginTop: '25px',
          }}
        >
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              color: '#555',
              textAlign: 'right',
              margin: 0,
            }}
          >
            금월 발전량: {totalAmountThisMonth.toLocaleString()} kWh
          </p>
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              color: '#555',
              textAlign: 'right',
              margin: 0,
            }}
          >
            전월 발전량: {totalAmountLastMonth.toLocaleString()} kWh
          </p>
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              color: '#555',
              textAlign: 'right',
              margin: 0,
            }}
          >
            일평균(전월): {avgTimeThisMonth} ({avgTimeLastMonth}) 시간
          </p>
        </div>
      )}
    </div>
  );
};

export default MgenerationCard;
