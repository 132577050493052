import React, { useEffect, useState } from 'react';
import { useDataProvider } from '../context/DataProvider';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import GridViewIcon from '@mui/icons-material/GridView';

const YgenerationCard = () => {
  const { getList } = useDataProvider();

  const [tableData, setTableData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [totalAmountThisYear, setTotalAmountThisYear] = useState(0);
  const [totalAmountLastYear, setTotalAmountLastYear] = useState(0);
  const [avgTimeThisYear, setAvgTimeThisYear] = useState(0);
  const [avgTimeLastYear, setAvgTimeLastYear] = useState(0);
  const [displayMode, setDisplayMode] = useState('graph'); // 기본 모드 설정

  useEffect(() => {
    const fetchData = async (retryCount = 3) => {
      try {
        const data = await getList({
          endpoint: 'plant/hyrogen/generation/yearly',
          params: {},
        });

        // 테이블 데이터 설정
        const thisYear = data.thisYear.data.map((item) => ({
          month: item.month.substring(4),
          amount: item.amount || 0,
          avgTime: item.avgTime || 0,
        }));

        // 그래프 데이터 설정
        const graphThisYear = thisYear.map((item) => ({
          month: item.month,
          thisYearAmount: item.amount,
        }));
        const lastYear =
          data.lastYear.data?.map((item) => ({
            month: item.month.substring(4),
            lastYearAmount: item.amount || 0,
          })) || [];

        setTableData(thisYear);
        setGraphData([...graphThisYear, ...lastYear]);
        setTotalAmountThisYear(data.thisYear.totalAmount || 0);
        setTotalAmountLastYear(data.lastYear.totalAmount || 0);
        setAvgTimeThisYear(data.thisYear.avgTime || 0);
        setAvgTimeLastYear(data.lastYear.avgTime || 0);
      } catch (err) {
        console.error('Data fetching error:', err.message);
        if (retryCount > 0) {
          console.log(`Retrying... Attempts left: ${retryCount - 1}`);
          fetchData(retryCount - 1);
        } else {
          console.error('Failed to fetch data after multiple attempts');
        }
      }
    };

    fetchData();
  }, [getList]);

  const formatMonth = (monthString) => {
    // 문자열의 마지막 두 자리만 추출하고 앞의 0을 제거
    const month = parseInt(monthString.slice(-2), 10); // '08' 같은 경우 8로 변환
    return `${month}월`;
  };

  const handleButtonClick = (mode) => {
    setDisplayMode(mode);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'relative',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: '10px',
        }}
      >
        <button
          onClick={() => handleButtonClick('graph')}
          style={{
            padding: '1px 3.5px',
            marginRight: '5px',
            backgroundColor: displayMode === 'graph' ? '#007BFF' : '#ddd',
            color: displayMode === 'graph' ? '#fff' : '#000',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            zIndex: 10,
          }}
        >
          <ShowChartIcon />
        </button>
        <button
          onClick={() => handleButtonClick('grid')}
          style={{
            padding: '1px 3.5px',
            backgroundColor: displayMode === 'grid' ? '#007BFF' : '#ddd',
            color: displayMode === 'grid' ? '#fff' : '#000',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            zIndex: 10,
          }}
        >
          <GridViewIcon />
        </button>
      </div>

      {displayMode === 'graph' && (
        <ResponsiveContainer width="105%" height={185}>
          <LineChart
            data={graphData}
            margin={{ top: 10, right: 15, left: 0, bottom: 5 }}
          >
            <XAxis
              dataKey="month"
              interval={1}
              tickFormatter={(value) => `${value}월`}
              tick={{ fontFamily: 'Roboto', fontSize: 12 }}
            />
            <YAxis
              domain={[0, (dataMax) => Math.ceil(dataMax * 1.1)]}
              tickFormatter={(value) => `${value} kWh`}
              tick={{ fontFamily: 'Roboto', fontSize: 12 }}
            />
            <Tooltip
              formatter={(value, name, props) => {
                if (name === 'thisYearAmount') {
                  return [`금년: ${value} kWh`];
                }
                if (name === 'lastYearAmount') {
                  return [`전년: ${value} kWh`];
                }
                return value;
              }}
              labelFormatter={(label) => `${parseInt(label, 10)}월`}
              itemStyle={{ color: '#8884d8' }} // 색상 조정 가능
            />

            <CartesianGrid strokeDasharray="3 3" />
            {graphData.length > 0 && (
              <Line
                type="monotone"
                dataKey="thisYearAmount"
                stroke="#8884d8"
                strokeWidth={2}
                dot={false}
                isAnimationActive={false}
              />
            )}
            {graphData.length > 0 && (
              <Line
                type="monotone"
                dataKey="lastYearAmount"
                stroke="#82ca9d"
                strokeWidth={2}
                dot={false}
                isAnimationActive={false}
              />
            )}
          </LineChart>
        </ResponsiveContainer>
      )}

      {displayMode === 'grid' && (
        <TableContainer
          component={Paper}
          style={{ marginTop: '10px', maxHeight: '320px', overflowY: 'auto' }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontSize: '16px',
                    padding: '3px',
                    textAlign: 'center',
                  }}
                >
                  월별
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '16px',
                    padding: '3px',
                    textAlign: 'center',
                  }}
                >
                  발전량 (kWh)
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '16px',
                    padding: '3px',
                    textAlign: 'center',
                  }}
                >
                  월평균발전시간 (시간)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    style={{
                      fontSize: '13px',
                      padding: '3px',
                      textAlign: 'center',
                    }}
                  >
                    {formatMonth(row.month)}
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '13px',
                      padding: '3px',
                      textAlign: 'center',
                    }}
                  >
                    {row.amount.toLocaleString()} kWh
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: '13px',
                      padding: '3px',
                      textAlign: 'center',
                    }}
                  >
                    {row.avgTime} 시간
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {displayMode === 'graph' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: '1px',
            marginTop: '25px',
          }}
        >
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              color: '#555',
              textAlign: 'right',
              margin: 0,
            }}
          >
            금년 발전량: {totalAmountThisYear.toLocaleString()} kWh
          </p>
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              color: '#555',
              textAlign: 'right',
              margin: 0,
            }}
          >
            전년 발전량: {totalAmountLastYear.toLocaleString()} kWh
          </p>
          <p
            style={{
              fontWeight: 'bold',
              fontSize: '16px',
              color: '#555',
              textAlign: 'right',
              margin: 0,
            }}
          >
            월평균(전년): {avgTimeThisYear} ({avgTimeLastYear}) 시간
          </p>
        </div>
      )}
    </div>
  );
};

export default YgenerationCard;
