import React, { createContext, useContext } from 'react';
import axios from 'axios';

// API 관리 함수 정의
const apiManager = async ({ endpoint, params }) => {
  const url = `https://api.h2rogen.com/${endpoint}`; // API 엔드포인트를 유연하게 사용
  const response = await axios.get(url, { params });
  return response.data;
};

// 데이터 제공자 정의
export const dataProvider = {
  getList: async ({ endpoint, params }) => {
    try {
      const response = await apiManager({ endpoint, params });
      return response;
    } catch (err) {
      console.error('getList 에러:', err.message);
      throw err;
    }
  },
  // 다른 메서드 추가 가능
};

// DataContext 생성
const DataContext = createContext(dataProvider);

// useDataProvider 훅
export const useDataProvider = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useDataProvider must be used within a DataProvider');
  }
  return context;
};

// DataProvider 컴포넌트
export const DataProvider = ({ children, dataProvider }) => {
  return (
    <DataContext.Provider value={dataProvider}>{children}</DataContext.Provider>
  );
};
